<template>
  <dialog-form-list
    :config="config"
    :formData="formData"
    @cancel="cancel"
    @confirm="confirm"
  />
</template>

<script>
export default {
  props: {
    // 当前选中的表格行数据
    tableRow: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      cmd: this.$store.state.cmd,
      formData: {
        osType: 'APK',
      },
      config: [
        // lang:是否强制更新
        {
          tag: 'el-select',
          label: this.$t('versionManage.forceUpdate'),
          prop: 'forceUpdate',
          rules: {
            noNull: true,
          },
          tagProps: {
            options: this.$dict.yesOrNo(),
          },
        },
        // lang:类型
        {
          tag: 'el-select',
          label: this.$t('versionManage.category'),
          prop: 'category',
          rules: {
            noNull: true,
          },
          tagProps: {
            options: [],
          },
        },
        // lang:版本类型
        {
          tag: 'el-select',
          label: this.$t('versionManage.versionType'),
          prop: 'versionType',
          rules: {
            noNull: true,
          },
          tagProps: {
            options: this.$dict.versionType(),
          },
        },
        // lang:标题
        {
          label: this.$t('versionManage.title'),
          prop: 'title',
          rules: {
            noNull: true,
          },
        },
        // lang:版本说明
        {
          label: this.$t('versionManage.version'),
          prop: 'version',
          rules: {
            noNull: true,
          },
          tagProps: {
            placeholder: '1.0.0',
          },
        },
        // lang:版本号
        {
          label: this.$t('versionManage.versionNumber'),
          prop: 'versionNumber',
          rules: {
            noNull: true,
            type: 'positiveInt',
          },
          tagProps: {
            placeholder: '100',
          },
        },
        // lang:版本描述
        {
          label: this.$t('versionManage.notes'),
          prop: 'notes',
          tagProps: {
            type: 'textarea',
            rows: 4,
          },
        },
        // lang:相关文件
        {
          tag: 'upload-file',
          label: this.$t('versionManage.appFile'),
          prop: 'appFile',
          rules: {
            noNull: true,
          },
          tagProps: {
            limit: 1,
            accept: '.apk',
            baseUrl: process.env.VUE_APP_FILE_UPLOAD_TYPE === 'SELF_API' ? document.location.origin : '',
            action: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.coreFileupload,
            fileFrom: 'VERSION_FILE',
          },
        },
      ],
    }
  },
  mounted() {
    this.setDefaultValue()
  },
  methods: {
    // 设置默认值
    setDefaultValue() {
      this.$nextTick(() => {
        const language = this.$util.getLocalStorage('lang')
        //默认值
        if (this.cmd !== 'add') {
          this.$util.setDefaultValue(this.formData, this.tableRow)
          this.formData.appFile = [this.tableRow.appFile]
          this.formData.category = this.tableRow.category.id
          this.dictFindTree(
            'VERSION_CATEGORY',
            this.tableRow.category ? this.tableRow.category.language : language
          )
        } else {
          this.dictFindTree('VERSION_CATEGORY', language)
        }
      })
    },
    // 查询字典
    dictFindTree(dictType, language) {
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.dictFindTree,
        data: {
          dictType,
          language,
        },
      }).then((data) => {
        this.config[1].tagProps.options = data.map((item) => ({
          label: item.value,
          value: item.id,
        }))
      })
    },
    // 取消
    cancel() {
      this.$emit('closePage', 'cancel')
    },
    // 确定
    confirm() {
      const formData = this.$util.copyData(this.formData)
      formData.category = { id: formData.category }
      formData.appFile = formData.appFile[0]
      switch (this.cmd) {
        case 'add':
          this.addMethod(formData)
          break
        case 'edit':
          this.editMethod(formData)
          break
        default: // do something
      }
    },
    // 新增
    addMethod(formData) {
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.versionAdd,
        data: formData,
      }).then(() => {
        // lang:新增成功
        this.$element.showMsg(this.$t('base.addSuccess'), 'success')
        this.$emit('closePage', 'add')
      })
    },
    // 修改
    editMethod(formData) {
      const tableRow = this.$util.copyData(this.tableRow)
      Object.assign(tableRow, formData)
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.versionUpdate,
        data: tableRow,
      }).then(() => {
        // lang:修改成功
        this.$element.showMsg(this.$t('base.editSuccess'), 'success')
        this.$emit('closePage', 'edit')
      })
    },
  },
}
</script>
