<template>
  <base-page
    :title="$t('versionManage.title_2')"
    :formData="formData"
    :config="config"
    @functionMethod="functionMethod"
    @search="search"
  >
    <table-list
      ref="tableList"
      :url="'/' + $util.getLocalStorage('apiprefix') + $api.versionFind"
      :columns="columns"
      :defaultParams="params"
      rowName="version"
      v-model="tableRow"
    >
      <!-- 创建时间 -->
      <template v-slot:createTime="{ row }">
        {{ $util.format(row.createTime, 'yyyy-MM-dd') }}
      </template>
      <!-- 操作 -->
      <template v-slot:option="{ row }">
        <template v-if="row.versionType === 'BETA'">
          <el-button type="text" @click="openDialogTableList(row, 'add')">
            {{ $t('versionManage.addUser') }}
          </el-button>
          <el-button type="text" @click="openDialogTableList(row, 'del')">
            {{ $t('versionManage.delUser') }}
          </el-button>
          <el-button type="text" @click="openDialogTableList(row, 'view')">
            {{ $t('versionManage.viewUser') }}
          </el-button>
        </template>
      </template>
    </table-list>
    <detail v-if="showDetail" :tableRow="tableRow" @closePage="closePage" />
    <dialog-table-list
      width="1200px"
      ref="dialogTableList"
      v-if="showDialogTableList"
      :title="dialogTitle"
      :url="dialogUrl"
      :defaultParams="dialogDefaultParams"
      :formData="dialogFormData"
      :multiple="dialogMultiple"
      :showConfirm="showConfirm"
      :pagination="dialogPagination"
      :config="dialogConfig"
      :columns="dialogColumns"
      @search="dialogSearch"
      @cancel="cancelDailogTableList"
      @confirm="confirmDailogTableList"
      v-model="dialogTableRows"
    ></dialog-table-list>
  </base-page>
</template>

<script>
import Detail from './detail'

export default {
  components: {
    Detail,
  },
  data() {
    const self = this
    return {
      // 搜索
      formData: {},
      config: [
        {
          tag: 'el-date-picker',
          label: this.$t('base.createTime'),
          prop: 'createTime',
          span: 8,
          className: 'daterangeDate',
          tagProps: {
            type: 'daterange',
            startPlaceholder: this.$t('base.startDate'),
            endPlaceholder: this.$t('base.endDate'),
          },
        },
      ],
      // 表格页
      tableRow: null,
      params: {
        osType: 'APK',
      },
      columns: [
        // lang:创建时间
        {
          label: this.$t('base.createTime'),
          prop: 'createTime',
        },
        // lang:是否强制更新
        {
          label: this.$t('versionManage.forceUpdate'),
          prop: 'forceUpdate',
          callback(row) {
            return self.$util.listToObj(self.$dict.yesOrNo())[row.forceUpdate]
          },
        },
        // lang:类型
        {
          label: this.$t('versionManage.category'),
          prop: 'category',
          callback(row) {
            return row.category.value
          },
        },
        // lang:终端类型
        {
          label: this.$t('versionManage.osType'),
          prop: 'osType',
          callback(row) {
            return self.$util.listToObj(self.$dict.osType())[row.osType]
          },
        },
        // lang:版本类型
        {
          label: this.$t('versionManage.versionType'),
          prop: 'versionType',
          callback(row) {
            return self.$util.listToObj(self.$dict.versionType())[
              row.versionType
            ]
          },
        },
        // lang:标题
        {
          label: this.$t('versionManage.title'),
          prop: 'title',
        },
        // lang:版本说明
        {
          label: this.$t('versionManage.version'),
          prop: 'version',
        },
        // lang:版本号
        {
          label: this.$t('versionManage.versionNumber'),
          prop: 'versionNumber',
        },
        // lang:版本描述
        {
          label: this.$t('versionManage.notes'),
          prop: 'notes',
        },
        // lang:操作
        {
          label: this.$t('base.option'),
          prop: 'option',
          width: '250px',
        },
      ],
      // 表单页
      showDetail: false,
      // 弹出表格
      dialogUrl: '',
      dialogTitle: '',
      dialogDefaultParams: {},
      dialogMultiple: false,
      dialogPagination: false,
      showDialogTableList: false,
      showConfirm: false,
      dialogConfig: [],
      dialogColumns: [
        // lang:所属部门
        {
          label: this.$t('adminAccount.accountGroup'),
          prop: 'accountGroup',
          callback(row) {
            if (row.userAccount) return row.userAccount.accountGroup.name
            return row.accountGroup.name
          },
        },
        // lang:姓名
        {
          label: this.$t('adminAccount.name'),
          prop: 'name',
          callback(row) {
            if (row.userAccount) return row.userAccount.name
            return row.name
          },
        },
        // lang:手机号
        {
          label: this.$t('adminAccount.mobile'),
          prop: 'mobile',
          callback(row) {
            if (row.userAccount) return row.userAccount.mobile
            return row.mobile
          },
        },
        // lang:登录名
        {
          label: this.$t('adminAccount.loginName'),
          prop: 'accountId',
          width: '180px',
          callback(row) {
            if (row.userAccount) return row.userAccount.accountId
            return row.accountId
          },
        },
        // lang:账号类型
        {
          label: this.$t('adminAccount.type'),
          prop: 'type',
          callback(row) {
            const userAccountType = self.$util.listToObj(
              self.$dict.userAccountType()
            )
            if (row.userAccount) return userAccountType[row.userAccount.type]
            return userAccountType[row.type]
          },
        },
        // lang:昵称
        {
          label: this.$t('adminAccount.nickname'),
          prop: 'nickname',
          callback(row) {
            if (row.userAccount) return row.userAccount.nickname
            return row.nickname
          },
        },
      ],
      dialogFormData: {},
      dialogTableRows: [],
    }
  },
  methods: {
    // 弹出搜索回掉
    dialogSearch() {
      this.$refs.dialogTableList.searchList(this.dialogFormData)
    },
    // 打开用户弹框
    openDialogTableList(row, type) {
      this.tableRow = row
      this.dialogType = type
      if (type === 'add') {
        this.$ajax({
          url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.versionUserAccountFind,
          data: {
            versionId: row.id,
          },
        }).then((data) => {
          // 添加用户
          this.dialogUrl = '/core' + this.$api.userAccountFind
          this.dialogDefaultParams = {
            excludeIdSet: data.map((item) => item.id),
          }
          this.dialogMultiple = true
          this.dialogPagination = true
          this.showConfirm = true
          this.dialogTitle = this.$t('versionManage.addUser')
          this.dialogConfig = [
            {
              label: this.$t('base.searchStr'),
              prop: 'searchStr',
              span: 10,
              tagProps: {
                placeholder: this.$t('adminAccount.nameOrNotes'),
              },
            },
            {
              tag: 'el-date-picker',
              label: this.$t('base.createTime'),
              prop: 'createTime',
              span: 10,
              className: 'daterangeDate',
              tagProps: {
                type: 'daterange',
                startPlaceholder: this.$t('base.startDate'),
                endPlaceholder: this.$t('base.endDate'),
              },
            },
          ]
          this.dialogTableRows = []
          this.$nextTick(() => {
            this.showDialogTableList = true
          })
        })
      } else if (type === 'del') {
        // 删除用户
        this.dialogUrl = '/' + this.$util.getLocalStorage('apiprefix') + this.$api.versionUserAccountFind
        this.dialogDefaultParams = {
          versionId: row.id,
        }
        this.dialogMultiple = true
        this.dialogPagination = false
        this.showConfirm = true
        this.dialogTitle = this.$t('versionManage.delUser')
        this.dialogConfig = []
        this.dialogTableRows = []
        this.$nextTick(() => {
          this.showDialogTableList = true
        })
      } else {
        // 查看用户
        this.dialogUrl = '/' + this.$util.getLocalStorage('apiprefix') + this.$api.versionUserAccountFind
        this.dialogDefaultParams = {
          versionId: row.id,
        }
        this.dialogMultiple = false
        this.dialogPagination = false
        this.showConfirm = false
        this.dialogTitle = this.$t('versionManage.viewUser')
        this.dialogConfig = []
        this.dialogTableRows = []
        this.$nextTick(() => {
          this.showDialogTableList = true
        })
      }
    },
    // 关闭用户弹框
    cancelDailogTableList() {
      this.showDialogTableList = false
    },
    // 确认用户弹框
    confirmDailogTableList(rows) {
      if (this.dialogType === 'add') {
        const { id } = this.tableRow
        const versionUserAccountList = rows.map((item) => ({
          versionEntity: { id: id },
          accountUid: item.uid,
        }))
        this.$ajax({
          url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.versionUserAccountAdd,
          data: {
            versionUserAccountList,
          },
        }).then(() => {
          // lang:添加成功
          this.$element.showMsg(this.$t('base.addSuccess'), 'success')
          this.showDialogTableList = false
          this.search()
        })
      } else if (this.dialogType === 'del') {
        this.$ajax({
          url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.versionUserAccountDelete,
          data: rows.map((item) => item.id),
        }).then(() => {
          // lang:删除成功
          this.$element.showMsg(this.$t('base.deleteSuccess'), 'success')
          this.showDialogTableList = false
          this.search()
        })
      }
    },
    // 关闭详情页回调
    closePage(method) {
      this.showDetail = false
      if (method && method !== 'cancel') {
        this.search()
      }
    },
    // 查询按钮点击回调
    search(isActiveQuery) {
      this.$refs.tableList.searchList(this.formData, isActiveQuery)
    },
    // 权限按钮点击回掉
    functionMethod(item) {
      const { method } = item
      if (method !== 'add' && method !== 'import' && !this.tableRow) {
        this.$element.showMsg(this.$t('base.selectTableRow'), 'warning')
        return
      }
      switch (method) {
        case 'add':
        case 'edit':
        case 'view':
          this.showDetail = true
          break
        case 'del':
          this.deleteMethod()
          break
        default: // do something
      }
    },
    // 删除
    deleteMethod() {
      this.$element
        .showConfirm({
          content: this.$t('base.deleteConfirm'),
        })
        .then(() => {
          this.$ajax({
            url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.versionDelete,
            data: this.tableRow,
          }).then(() => {
            // lang:删除成功
            this.$element.showMsg(this.$t('base.deleteSuccess'), 'success')
            this.search()
          })
        })
    },
  },
}
</script>
